import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { arrayMove } from 'react-sortable-hoc'

import * as Icons from '~/client/src/shared/components/Icons'
import Sitemap from '~/client/src/shared/models/Sitemap'
import InitialState from '~/client/src/shared/stores/InitialState'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'

import EventsStore from '../../stores/EventStore/Events.store'
import EventTypes from '../../stores/EventStore/eventTypes'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import WeatherForecastsStore from '../../stores/domain/WeatherForecasts.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import CompactSitemapPicker from '../CompactSitemapPicker/CompactSitemapPicker'
import FileInputBase from '../FileInput/FileInput'
import MapBoxEditorStore from '../MapBoxEditor/MapBoxEditor.store'
import WeatherLabel from '../WeatherLabel/WeatherLabel'
import PinchZoomPan from './PinchZoomPan'

import './SitemapsGallery.scss'

import LogisticsCreationListMenu from '../SitemapCards/LogisticsCreationListMenu'

const MIN_SCALE = 1
const MAX_SCALE = 8

export interface IProps {
  eventName: EventTypes

  selectSitemap?: (sitemapIndex: number) => void
  sitemapsIds?: string[]
  sitemapsStore?: SitemapsStore
  openPermitCreationForm?: () => void
  openAnnouncementCreationForm?: () => void
  state?: InitialState
  onHide?: () => void
  isHideButtonDisplayed?: boolean
  SelectedSitemapComponent?: any
  shouldDisableZoom?: boolean
  shouldShowCreateNewButton?: boolean
  eventsStore?: EventsStore
  permitTypesStore?: PermitTypesStore
  shouldDisableSwap?: boolean
  areArrowsLeftSided?: boolean
  currentDate?: Date
  shouldUseFullHeight?: boolean
  shouldIncreasedIcons?: boolean
  isCompactMode?: boolean
  weatherForecastsStore?: WeatherForecastsStore
  projectDateStore?: ProjectDateStore
  mapboxEditorStore?: MapBoxEditorStore

  renderAdditionalRightAction?: () => JSX.Element

  isCreationLimited?: boolean
  renderCreateButton?: () => JSX.Element
  FileInputType: typeof FileInputBase

  hasFiltersBar?: boolean
}

@inject(
  'state',
  'sitemapsStore',
  'eventsStore',
  'permitTypesStore',
  'weatherForecastsStore',
  'projectDateStore',
)
@observer
export default class SitemapsGallery extends React.Component<IProps> {
  @observable public isGallerySwipingDisabled: boolean = false
  @observable public isSelectMenuShown: boolean = false
  @observable public isLayersMenuShown: boolean = false
  @observable public currentSitemapId: string = null
  @observable public currentSitemapIndex: number = 0
  @observable public searchKey: string = ''
  @observable private isSliding: boolean = false

  public constructor(props: IProps) {
    super(props)
    const { selectSitemap } = this.props
    this.currentSitemapId = this.getSitemapIdByIndex(0)
    if (selectSitemap) {
      selectSitemap(0)
    }
  }

  public componentDidMount() {
    const { currentDate, weatherForecastsStore } = this.props
    if (currentDate) {
      weatherForecastsStore.fetchWeeklyForecast(currentDate)
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    this.selectImageById(this.currentSitemapId)
    const { currentDate, weatherForecastsStore } = this.props

    if (!!currentDate && prevProps.currentDate !== currentDate) {
      weatherForecastsStore.fetchWeeklyForecast(currentDate)
    }
  }

  public componentWillUnmount(): void {
    this.props.mapboxEditorStore.resetMapboxInfo()
  }

  @computed
  private get sitemapsIds(): string[] {
    const { byId } = this.props.sitemapsStore
    return this.props.sitemapsIds.filter(id => !!byId.get(id))
  }

  public render() {
    const { shouldUseFullHeight, FileInputType, isCompactMode } = this.props

    return (
      <>
        {this.isSelectMenuShown && (
          <CompactSitemapPicker
            onClose={this.toggleSelectMenu}
            onApply={this.selectSitemap}
            selectedSitemapId={this.currentSitemapId}
            sitemapsIds={this.sitemapsIds}
            reassignSitemapsOrders={this.reassignSitemapsOrders}
            FileInputType={FileInputType}
            withPressDelay={isCompactMode}
          />
        )}

        <div
          className={classList({
            'sitemaps relative': true,
            'full-height': shouldUseFullHeight,
          })}
        >
          {this.renderSitemapsContent()}
        </div>
      </>
    )
  }

  private renderSitemapsContent() {
    const {
      isHideButtonDisplayed,
      onHide,
      shouldShowCreateNewButton,
      openAnnouncementCreationForm,
      openPermitCreationForm,
      renderAdditionalRightAction,
      isCreationLimited,
    } = this.props

    return (
      <div
        className={classList({
          'sitemaps-gallery absolute full-height full-width': true,
          'select-menu-opened': this.isSelectMenuShown,
        })}
      >
        {this.renderViewSelectMenu()}
        {isHideButtonDisplayed && (
          <Icons.Cross className="cross-icon" onClick={onHide} />
        )}
        {renderAdditionalRightAction && renderAdditionalRightAction()}

        {shouldShowCreateNewButton && (
          <LogisticsCreationListMenu
            openAnnouncementCreationForm={openAnnouncementCreationForm}
            openPermitCreationForm={openPermitCreationForm}
            isPermitOnlyMode={isCreationLimited}
            className="map-view"
          />
        )}
        {this.renderItem()}
      </div>
    )
  }

  private renderViewSelectMenu() {
    const {
      isCompactMode,
      shouldIncreasedIcons,
      weatherForecastsStore: { weatherByDaysList, weatherUnits },
      projectDateStore,
      currentDate,
      sitemapsStore,
    } = this.props
    const selectedSitemap = sitemapsStore.byId.get(this.currentSitemapId)

    const weatherForecast = weatherByDaysList.find(w =>
      projectDateStore.isSameDay(w.date, currentDate),
    )

    return (
      <div
        className={classList({
          'absolute row items-holder': true,
          'top-offset': shouldIncreasedIcons,
          'full-width row x-between y-start px16 left-offset': isCompactMode,
          mt40: this.props.hasFiltersBar,
        })}
      >
        {isCompactMode ? (
          <>
            <div className="no-grow row">
              <div className="brada4 no-grow bg-white row align-start x-center y-center weather-block">
                <WeatherLabel
                  className="px8 no-grow"
                  forecast={weatherForecast}
                  projectWeatherUnits={weatherUnits}
                  shouldHideProbabilityOfPrecipitation={true}
                  shouldHideWind={true}
                />
              </div>
            </div>
            <div className="column bg-white map-icon-group no-grow brada4">
              {selectedSitemap?.isReferenced && (
                <div
                  className="no-grow pa5 row x-center y-center map-icon bb-input-border"
                  onClick={this.toggleLayersMenu}
                >
                  <Icons.MoreLayerStylesSmall className="no-grow" />
                </div>
              )}
              <div
                className="no-grow pa5 row x-center y-center map-icon"
                onClick={this.toggleSelectMenu}
              >
                <Icons.SitemapSelector className="no-grow" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="text white-back current-map-name pa8 row mw-fit-content mr8 pointer"
              onClick={this.toggleSelectMenu}
            >
              <Icons.Sitemap className="no-grow" />
              <span className="ml8 text-ellipsis">{selectedSitemap?.name}</span>
            </div>

            <div className="text white-back arrows-holder text-ellipsis pa5 mw-fit-content row">
              <div className="no-grow" onClick={this.selectPrevMap}>
                <Icon icon={IconNames.CHEVRON_LEFT} />
              </div>
              <div className="text center sitemap-counts">
                {this.currentMapText}
              </div>
              <div className="no-grow" onClick={this.selectNextMap}>
                <Icon icon={IconNames.CHEVRON_RIGHT} />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  private toggleSelectMenu = () => {
    this.isSelectMenuShown = !this.isSelectMenuShown
  }

  private toggleLayersMenu = () => {
    this.isLayersMenuShown = !this.isLayersMenuShown
  }

  private reassignSitemapsOrders = ({ oldIndex, newIndex }) => {
    const { eventsStore, eventName, sitemapsStore } = this.props

    if (oldIndex !== newIndex) {
      const indexedSitemapsIds = arrayMove(this.sitemapsIds, oldIndex, newIndex)

      const orderedSitemaps = indexedSitemapsIds.map((sitemapId, index) => {
        return {
          sitemapId,
          order: index,
        }
      })
      eventsStore.dispatch(eventName, {
        sitemaps: orderedSitemaps,
      })
    } else {
      const sitemap = sitemapsStore.byId.get(this.sitemapsIds[oldIndex])
      this.selectSitemap(sitemap)
    }

    this.onSlide(newIndex, false)
  }

  private selectSitemap = (sitemap: Sitemap) => {
    this.selectImageById(sitemap.id)
    this.isSelectMenuShown = false
  }

  private selectImageById = (id: string) => {
    const index = this.sitemapsIds.findIndex(sitemapId => sitemapId === id)
    this.onSlide(index, false)
  }

  private onSlide = (currentIndex: number, isSliding: boolean) => {
    const { selectSitemap, mapboxEditorStore } = this.props
    if (this.currentSitemapIndex !== currentIndex) {
      this.currentSitemapIndex = currentIndex
      this.currentSitemapId = this.getSitemapIdByIndex(currentIndex)

      if (mapboxEditorStore) {
        this.props.mapboxEditorStore.setViewportFromAdress()
        this.props.mapboxEditorStore.setItems()
        this.props.mapboxEditorStore.resetMapboxInfo()
        this.props.mapboxEditorStore.removeRefs()
      }
      if (selectSitemap) {
        selectSitemap(currentIndex)
      }
    }

    this.isSliding = isSliding
  }

  private getSitemapIdByIndex(index: number): string {
    return this.sitemapsIds[index]
  }

  private renderItem = () => {
    if (this.props.shouldDisableZoom) {
      return this.renderImage()
    }

    return (
      <PinchZoomPan
        maxScale={MAX_SCALE}
        minScale={MIN_SCALE}
        zoomButtons={false}
        position="center"
        doubleTapBehavior="zoom"
        onScaleChanged={this.onScaleChanged}
      >
        {this.renderImage()}
      </PinchZoomPan>
    )
  }

  private renderImage = () => {
    const { SelectedSitemapComponent, sitemapsStore } = this.props

    if (
      this.currentSitemapId &&
      SelectedSitemapComponent &&
      !this.isSelectMenuShown &&
      !this.isSliding
    ) {
      return (
        <div className="full-width full-height selected-sitemap">
          <SelectedSitemapComponent
            sitemapId={this.currentSitemapId}
            isLayersMenuShown={this.isLayersMenuShown}
            toggleLayersMenu={this.toggleLayersMenu}
          />
        </div>
      )
    }
    const selectedItemId = this.sitemapsIds[this.currentSitemapIndex]
    const selectedSitemap = sitemapsStore.byId.get(selectedItemId)
    return <img src={selectedSitemap?.filledImage} />
  }

  private onScaleChanged = (scale: number) => {
    this.isGallerySwipingDisabled = scale > MIN_SCALE
  }

  private selectNextMap = () => {
    this.isSliding = true
    if (this.currentSitemapIndex === this.sitemapsIds.length - 1) {
      this.onSlide(0, true)
      return
    }

    this.onSlide(this.currentSitemapIndex + 1, true)
  }

  private selectPrevMap = () => {
    this.isSliding = true
    if (this.currentSitemapIndex === 0) {
      this.onSlide(this.sitemapsIds.length - 1, true)
      return
    }

    this.onSlide(this.currentSitemapIndex - 1, true)
  }

  private get currentMapText(): string {
    return `${this.currentSitemapIndex + 1} / ${this.sitemapsIds.length}`
  }
}
